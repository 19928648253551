
const initialState = {
  currentLevel: null,
  activeBrush: 'Box',
  
  levels: [
    {
      id: 'c08dcb39-5df4-4817-aa30-b9700b462e03',
      size: 4,
      turns: 3,
      name: 'Hello!',
      items: [
        {
          x: 0,
          y: 0,
          type: 'Box'
        },
        {
          x: 0,
          y: 3,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 0,
          type: 'Fixed'
        },
        {
          x: 3,
          y: 3,
          type: 'Exit'
        }
      ]
    },
    {
      id: '66003b58-605d-4b9d-9cd2-ad046f183295',
      size: 6,
      turns: 4,
      name: 'It\'s level 2',
      items: [
        {
          x: 1,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 2,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 2,
          type: 'Box'
        },
        {
          x: 1,
          y: 0,
          type: 'Fixed'
        },
        {
          x: 0,
          y: 0,
          type: 'Exit'
        }
      ]
    },
    {
      id: '3b75c461-bb05-4973-9eef-056bef4ae24b',
      size: 6,
      turns: 6,
      name: 'Beware!',
      items: [
        {
          x: 1,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 2,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 2,
          type: 'Box'
        },
        {
          x: 1,
          y: 0,
          type: 'Fixed'
        },
        {
          x: 0,
          y: 0,
          type: 'Exit'
        },
        {
          x: 0,
          y: 5,
          type: 'Killer'
        }
      ]
    },
    {
      id: '143f698a-8034-47e7-992b-7ddbf23318a8',
      size: 5,
      turns: 6,
      name: 'Dual',
      items: [
        {
          x: 2,
          y: 0,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 2,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 3,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 0,
          y: 0,
          type: 'Box'
        },
        {
          x: 4,
          y: 0,
          type: 'Box'
        },
        {
          x: 1,
          y: 4,
          type: 'Exit'
        },
        {
          x: 3,
          y: 4,
          type: 'Exit'
        },
        {
          x: 0,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 1,
          y: 0,
          type: 'Fixed'
        },
        {
          x: 3,
          y: 0,
          type: 'Fixed'
        }
      ]
    },
    {
      id: 'd8b5e2f6-f74e-4ae9-8eea-81d0db727a0f',
      size: 7,
      turns: 8,
      name: 'Bug',
      items: [
        {
          x: 1,
          y: 0,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 3,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 5,
          y: 0,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 5,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 3,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 2,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 5,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 3,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 2,
          type: 'Fixed'
        },
        {
          x: 6,
          y: 0,
          type: 'Exit'
        },
        {
          x: 0,
          y: 0,
          type: 'Exit'
        },
        {
          x: 3,
          y: 2,
          type: 'Box'
        },
        {
          x: 3,
          y: 3,
          type: 'Box'
        },
        {
          x: 1,
          y: 5,
          type: 'Fixed'
        },
        {
          x: 5,
          y: 5,
          type: 'Fixed'
        },
        {
          x: 5,
          y: 3,
          type: 'Fixed'
        },
        {
          x: 1,
          y: 3,
          type: 'Fixed'
        },
        {
          x: 1,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 5,
          y: 1,
          type: 'Fixed'
        }
      ]
    },
    {
      id: '79dcf101-5144-49ee-95ce-970529fae250',
      size: 7,
      turns: 12,
      name: 'Bug 2',
      items: [
        {
          x: 1,
          y: 0,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 3,
          y: 1,
          type: 'Fixed'
        },
        {
          x: 5,
          y: 0,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 5,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 3,
          type: 'Fixed'
        },
        {
          x: 2,
          y: 2,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 5,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 3,
          type: 'Fixed'
        },
        {
          x: 4,
          y: 2,
          type: 'Fixed'
        },
        {
          x: 1,
          y: 2,
          type: 'Fixed'
        },
        {
          x: 5,
          y: 2,
          type: 'Fixed'
        },
        {
          x: 5,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 1,
          y: 4,
          type: 'Fixed'
        },
        {
          x: 6,
          y: 0,
          type: 'Exit'
        },
        {
          x: 0,
          y: 0,
          type: 'Exit'
        },
        {
          x: 3,
          y: 2,
          type: 'Box'
        },
        {
          x: 3,
          y: 3,
          type: 'Box'
        }
      ]
    }
  ]
};

export default initialState;