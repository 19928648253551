const uuidv4 = require('uuid/v4');

export default function(state = {}, action) {
  switch (action.type) {
  case 'ADD_LEVEL':
    const newLevel = {
      id: uuidv4(),
      name: '',
      size: 6,
      turns: 0,
      items: []
    };

    return Object.assign({}, state, {
      currentLevel: newLevel,
      levels: state.levels.concat(newLevel)
    });
  case 'UPDATE_CURRENT_LEVEL':
    const updatedLevel = Object.assign( state.currentLevel, action.data );
    return Object.assign({}, state, {
      levels: state.levels.map(level => {
        if (level.id === updatedLevel.id) {
          return updatedLevel;
        } else {
          return level;
        }
      }),
      currentLevel: updatedLevel,
    });
  case 'SET_CURRENT_LEVEL':
    return Object.assign({}, state, { currentLevel: action.level });
  case 'REPLACE_LEVELS':
    return Object.assign({}, state, {
      currentLevel: null,
      levels: action.levels,
    });
  case 'DELETE_LEVEL':
    if (state.currentLevel) {
      return Object.assign({}, state, {
        currentLevel: null,
        levels: state.levels.filter(level => level.id !== state.currentLevel.id),
      });
    } else return state;
  case 'MOVE_LEVEL_UP':
    if (state.currentLevel) {
      const index = state.levels.findIndex(level => level.id === state.currentLevel.id);
      if (index > 0) {
        const levels = Array.from(state.levels);
        levels.splice(index - 1, 0, ...levels.splice(index, 1));

        return Object.assign({}, state, {
          levels
        });
      } else return state;
    } else return state;
  case 'MOVE_LEVEL_DOWN':
    if (state.currentLevel) {
      const index = state.levels.findIndex(level => level.id === state.currentLevel.id);
      if (index < state.levels.length - 1) {
        const levels = Array.from(state.levels);
        levels.splice(index + 1, 0, ...levels.splice(index, 1));

        return Object.assign({}, state, {
          levels
        });
      } else return state;
    } else return state;
  case 'SET_BRUSH':
    return Object.assign({}, state, { activeBrush: action.brush });
  case 'ROTATE_LEFT':
    if (!state.currentLevel) {
      return state;
    } else {
      const items = state.currentLevel.items.map(item => ({
        x: item.y,
        y: state.currentLevel.size - 1 - item.x,
        type: item.type
      }));

      const updatedLevel = Object.assign( state.currentLevel, { items } );
      return Object.assign({}, state, {
        levels: state.levels.map(level => {
          if (level.id === updatedLevel.id) {
            return updatedLevel;
          } else {
            return level;
          }
        }),
        currentLevel: updatedLevel,
      });
    }
  case 'ROTATE_RIGHT':
    if (!state.currentLevel) {
      return state;
    } else {
      const items = state.currentLevel.items.map(item => ({
        x: state.currentLevel.size - 1 - item.y,
        y: item.x,
        type: item.type
      }));

      const updatedLevel = Object.assign( state.currentLevel, { items } );
      return Object.assign({}, state, {
        levels: state.levels.map(level => {
          if (level.id === updatedLevel.id) {
            return updatedLevel;
          } else {
            return level;
          }
        }),
        currentLevel: updatedLevel,
      });
    }
  default:
    return state;
  }
}