import React, { Component } from 'react';
import { connect } from "react-redux";
import styled from 'styled-components';
import { FormGroup, H5, InputGroup, Intent, Switch, Divider, Tree, ButtonGroup, Button } from "@blueprintjs/core";
import { CELL_TYPES } from './data';
import { saveAs } from 'file-saver';

const SidebarContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 400px;
  bottom: 0;
  background: #ebf1f5;
  padding: 20px;
`;

const Palette = styled.div`
  position: relative;
  display: flex;
`;

const PaletteItem = styled.div`
  cursor: pointer;
  margin-right: 4px;
`;

class Sidebar extends Component {
  save = () => {
    const { levels } = this.props;
    
    const data = JSON.stringify({ levels });
    const blob = new Blob([data], {type: "application/json;charset=utf-8"});
    saveAs(blob, "levels.json");
    /*
    const data = levels.map(level => {
      return [[level.id, level.size, level.turns, level.name].join(','), ...level.items.map(item => [item.x, item.y, item.type].join(',')) ].join("\n")
    }).join("\n\n");

    const blob = new Blob([data], {type: "text/plain;charset=utf-8"});
    saveAs(blob, "levels.txt");
    */
  }

  load = () => {
    const { replaceLevels } = this.props;

    const fi = document.createElement('input');
    fi.type = 'file';
    fi.style.display = 'none';
    fi.onchange = (e) => {
      const file = e.target.files[0];
      if (!file) {
        document.body.removeChild(fi);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const textData = e.target.result;
        
        const levels = JSON.parse(textData).levels;

        /*
        const levels = textData.split("\n\n").map(level => {
          const levelData = level.split("\n");
          const info = levelData.shift().split(',', 4);

          return {
            id: info[0],
            size: parseInt(info[1])|0,
            turns: parseInt(info[2])|0,
            name: info[3],
            items: levelData.map(line => {
              const itemData = line.split(',');

              return {
                x: parseInt(itemData[0]),
                y: parseInt(itemData[1]),
                type: itemData[2],
              }
            })
          };
        });*/
        
        replaceLevels(levels);

        document.body.removeChild(fi);
      }
      reader.readAsText(file);
    };
    document.body.appendChild(fi);
    fi.click();
  }

  render() {
    const { currentLevel, levels, activeBrush, updateCurrentLevel, setCurrentLevel, addLevel, deleteLevel, moveUp, moveDown, setBrush, rotateLeft, rotateRight } = this.props;

    return (
      <SidebarContainer>
        <FormGroup label="Level name">
          <InputGroup disabled={!currentLevel} value={currentLevel && currentLevel.name} onChange={e => updateCurrentLevel({ name: e.target.value })} />
        </FormGroup>
        <FormGroup label="Level size">
          <InputGroup disabled={!currentLevel} type="number" value={currentLevel && currentLevel.size} onChange={e => updateCurrentLevel({ size: e.target.value })} />
        </FormGroup>
        <FormGroup label="Turns to win">
          <InputGroup disabled={!currentLevel} type="number" value={currentLevel && currentLevel.turns} onChange={e => updateCurrentLevel({ size: e.target.turns })} />
        </FormGroup>

        <Divider />

        <Palette>
          {CELL_TYPES.map(type => (
            <PaletteItem
              key={type}
              className={`grid-cell grid-cell-${type} ${activeBrush === type ? 'active' : null}`}
              title={type}
              onClick={(e) => { e.preventDefault(); setBrush(type); }}
            />
          ))}
        </Palette>

        <Divider />

        <ButtonGroup fill style={{ marginBottom: '5px' }}>
          <Button icon="add" onClick={() => addLevel()}></Button>
          <Button icon="delete" onClick={() => deleteLevel()}></Button>
          <Button icon="arrow-up" onClick={() => moveUp()}></Button>
          <Button icon="arrow-down" onClick={() => moveDown()}></Button>
          <Button icon="image-rotate-left" onClick={() => rotateLeft()}></Button>
          <Button icon="image-rotate-right" onClick={() => rotateRight()}></Button>
          <Button icon="floppy-disk" onClick={() => this.save()}></Button>
          <Button icon="folder-open" onClick={() => this.load()}></Button>
        </ButtonGroup>

        <div style={{ flex: 1, overflowY: 'auto' }}>
          <Tree contents={ levels.map(level => ({
            key: level.id,
            id: level.id,
            label: `${level.name} (${level.size}x${level.size})`,
            depth: 0,
            path: [],
            isSelected: currentLevel && level.id === currentLevel.id,
            hasCaret: true,
          })) } onNodeClick={(node) => { setCurrentLevel(levels.find(x => x.id === node.id)) }} />
        </div>
      </SidebarContainer>
    )
  }
}

const mapStateToProps = state => ({
  currentLevel: state.currentLevel,
  activeBrush: state.activeBrush,
  levels: state.levels,
});

const mapDispatchToProps = dispatch => ({
  updateCurrentLevel: (data) => dispatch({
    type: 'UPDATE_CURRENT_LEVEL',
    data,
  }),
  setCurrentLevel: (level) => dispatch({
    type: 'SET_CURRENT_LEVEL',
    level,
  }),
  addLevel: () => dispatch({
    type: 'ADD_LEVEL',
  }),
  deleteLevel: () => dispatch({
    type: 'DELETE_LEVEL',
  }),
  moveUp: () => dispatch({
    type: 'MOVE_LEVEL_UP',
  }),
  moveDown: () => dispatch({
    type: 'MOVE_LEVEL_DOWN',
  }),
  setBrush: (brush) => dispatch({
    type: 'SET_BRUSH',
    brush
  }),
  replaceLevels: (levels) => dispatch({
    type: 'REPLACE_LEVELS',
    levels
  }),
  rotateLeft: () => dispatch({
    type: 'ROTATE_LEFT',
  }),
  rotateRight: () => dispatch({
    type: 'ROTATE_RIGHT',
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
