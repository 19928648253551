import { applyMiddleware, compose, createStore } from 'redux'
import localforage from 'localforage';

import rootReducer from "./reducers";
import initialState from './initialState';

const localStorageMiddleware = ({getState}) => {
  return (next) => (action) => {
    const result = next(action);
    localforage.setItem('applicationState', getState());
    return result;
  };
};

export const initStore = (callback) => {
  localforage.getItem('applicationState', (err, value) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
      rootReducer,
      value || initialState,
      composeEnhancers(
        applyMiddleware(
          localStorageMiddleware,
        ),
      ),
    );

    callback(store);
  });
}