import React, { Component } from 'react';
import { connect } from "react-redux";
import { FormGroup, H5, InputGroup, Intent, Switch, Divider, Tree, ButtonGroup, Button } from "@blueprintjs/core";

class Viewport extends Component {
  constructor(props) {
    super(props);
  }

  setItem = (x, y, type) => {
    const { currentLevel, updateCurrentLevel } = this.props;

    const existing = currentLevel.items.find(obj => obj.x === x && obj.y === y);
    const items = currentLevel.items.filter(obj => !(obj.x === x && obj.y === y));
    if (type !== 'None' && (!existing || existing.type !== type)) {
      items.push({
        x, y, type
      });
    }

    updateCurrentLevel({ items });
  }

  render() {
    const { currentLevel, activeBrush } = this.props;

    if (!currentLevel) {
      return <div id="viewport" />;
    }

    const grid = [];
    for (let j = currentLevel.size - 1; j >= 0; j--) {
      const row = [];

      for (let i = 0; i < currentLevel.size; i++) {
        const obj = currentLevel.items.find(obj => obj.x === i && obj.y === j);
        const type = obj ? obj.type : 'None';

        row.push(
          <div key={i} className={`grid-cell grid-cell-${type}`} onClick={() => this.setItem(i, j, activeBrush)} />
        );
      }

      grid.push(
        <div className="grid-row" key={j}>
          {row}
        </div>
      );
    }

    return (
      <div id="viewport">
        <div className="grid">
          {grid}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentLevel: state.currentLevel,
  activeBrush: state.activeBrush,
  levels: state.levels,
});

const mapDispatchToProps = dispatch => ({
  updateCurrentLevel: (data) => dispatch({
    type: 'UPDATE_CURRENT_LEVEL',
    data,
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Viewport);
